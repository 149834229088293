<template>
    <div id="receiveProduct">
      <v-container>
        <v-row>
          <v-container>
            <h2>
              ใบรับของเข้า
            </h2>
          </v-container>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="dataTable"
              :items-per-page="10"
              class="elevation-1"
              :search="searchTxt"
            >
              <template v-slot:top>
                <v-row class="mx-2" no-gutters>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="searchTxt"
                      append-icon="mdi-magnify"
                      label="ค้นหา"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-btn color="info" dark class="ma-3" @click="showClick">
                      {{ showTxt }}
                    </v-btn>
                    <v-btn color="primary" dark class="my-3" @click="addLine">
                      เพิ่มรายการ
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.receive_id="{ item }" @click="showDetail(item.receive_id)">
                {{ item.receive_id }}
              </template>
              <template v-slot:item.detail="{ item }">
                <v-icon @click="showDetail(item.receive_id)" class="info--text">
                  mdi-clipboard-check-outline
                </v-icon>
              </template>
              <template v-slot:item.created_date="{ item }">
                {{ item.created_date | moment("DD/MM/YYYY") }}
              </template>
              <template v-slot:item.receive_date="{ item }">
                {{ item.receive_date | moment("DD/MM/YYYY") }}
              </template>
              <template v-slot:item.modified_date="{ item }">
                {{ item.modified_date | moment("DD/MM/YYYY") }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon class="mr-3 primary--text" @click="deleteDocument(item)" color="red">
                  mdi-delete-forever
                </v-icon>
              </template>
              <template v-slot:item.status="{ item }">
                <v-icon class="info--text" v-if="item.status == 2">
                  mdi-check-bold
                </v-icon>
                <v-icon class="info--text" v-if="item.status == 1">
                  mdi-check-bold
                </v-icon>
                <v-icon class="secondary--text" v-if="item.status == 0">
                  mdi-close-thick
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <dialogEdit
        :modal="showDialogEdit"
        :item="itemDialogEdit"
        :itemIndex="itemIndexDialogEdit"
        :itemIndexViewer="itemIndexDialogViewer"
        @close="closeDialogEdit"
      ></dialogEdit>
    </div>
  </template>
  
  <script>
  import dialogEdit from "./DialogReceiveDocument";
  import { newProduct } from "@/store/modules/settings/state";
  import { mapActions, mapState } from "vuex";
  export default {
    name: "receiveProduct",
    components: {
      dialogEdit,
    },
    data() {
      return {
        dataTableAll: [],
        showDialogEdit: false,
        itemDialogEdit: {},
        itemIndexDialogEdit: -1,
        itemIndexDialogViewer: -1,
        showTxt: "แสดงทั้งหมด",
        searchTxt: "",
        headers: [
          {
            text: "",
            value: "detail",
            divider: true,
            align: "center",
          },
          {
            text: "วันที่",
            value: "created_date",
            divider: true,
            width: 130,
          },
          {
            text: "รหัสเอกสาร",
            value: "receive_id",
            divider: true,
            width: 150,
          },
          {
            text: "วันที่รับเข้า",
            value: "receive_date",
            divider: true,
            align: "end",
          },
          {
            text: "สร้างโดย",
            value: "created_by",
            divider: true,
            align: "end",
          },
          {
            text: "แก้ไขล่าสุดโดย",
            value: "modified_by",
            divider: true,
            align: "end",
          },
          {
            text: "วันที่แก้ไขล่าสุด",
            value: "modified_date",
            divider: true,
            align: "end",
          },
          {
            text: "สถานะ",
            value: "status",
            divider: true,
            width: 130,
            align: "center",
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
      };
    },
    mounted() {
      this.loadData();
    },
    computed: {
      ...mapState("auth", ["user"]),
      dataTable: function() {
        if (this.showTxt == "แสดงทั้งหมด") {
          return this.dataTableAll.filter((list) => list.status == 1 || list.status == 2);
        } else {
          return this.dataTableAll;
        }
      },
    },
    methods: {
      ...mapActions("settings", [
        "ReceiveDocumentGets", 
        "ReceiveDocumentGet", 
        "searchMaterial", 
        "receiveDocumentDelete"
      ]),
      showClick() {
        if (this.showTxt == "แสดงทั้งหมด") {
          this.showTxt = "เฉพาะใช้งาน";
        } else {
          this.showTxt = "แสดงทั้งหมด";
        }
      },
      loadData() {
        this.ReceiveDocumentGets().then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.dataTableAll = data.result;
          } else {
            this.Error(data.message);
          }
        });
      },
      
      addLine() {
        this.itemDialogEdit = Object.assign({}, newProduct());
        this.itemIndexDialogViewer = -1;
        this.itemIndexDialogEdit = -1;
        this.showDialogEdit = true;
      },
      editLine(item) {
        this.itemDialogEdit = item;
        this.itemIndexDialogEdit = this.dataTable.indexOf(item);
        this.showDialogEdit = true;
      },
      deleteDocument(item){
        const body = {
          receive_id: item.receive_id,
          modified_by: this.user.UserId,
        }
        this.Confirm("ยืนยันการลบ").then((result)=> {
          if(result)
          this.receiveDocumentDelete(body).then(({ status, data }) => {
            if (status == 200 && data.status == "success") {
              this.Success("SUCCESS")
              this.loadData();
              // this.closeModal();
            } else {
              this.Error(data.result);
              this.loadData();
            }
          });
        })

      },
      showDetail(document_number) {
        this.ReceiveDocumentGet(document_number).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            const moment = require("moment")
            data.result.receive_date = moment(data.result.receive_date).format("DD/MM/YYYY")
            this.itemDialogEdit = data.result;
          } else {
            this.Error(data.message);
          }})
        this.itemIndexDialogViewer = true;
        this.showDialogEdit = true;
      },
      closeDialogEdit(value) {
        this.showDialogEdit = value;
        this.itemIndexDialogViewer = value
        this.loadData();
      },
    },
  };
  </script>
  
  <style>
  .container{
    max-width: 1500px;
  }
  </style>
  